<template>
  <div id="section_wwd">
    <Navigator />
    <div id="cnt_wwd">
      <h1 class="title_wwd" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="600">
        What <span>We Do</span>
      </h1>
      <div id="movil_wwd">
        <div class="bloq_wwd" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="600">
          <img class="bloq_hdr" src="../assets/servicios/mv1.png" alt="header bloq image" />
          <div class="dflx cnt_bloq_wwd">
            <div class="ico_wwd">
              <img class="ico1" src="../assets/servicios/ico1.png" alt="icon" />
            </div>
            <div class="data_wwd">
              <h2 class="subtitle_wwd">Hardware Controls System Design</h2>
              <p>
                We provide complete design of the automated systems for
                Automotive, Mining and food and beverage. Design of networks,
                electrical, pneumatic, and hydraulic using technological
                innovation in the automation industry.
              </p>
              <p>
                Our team provides the design department, information that is
                needed for development and integration of a functional system.
              </p>

              <ul>
                <li>Layouts</li>
                <li>
                  Communication protocols: Ethernet, Devicenet, Profinet, etc.
                </li>
                <li>Software Design – AutoCAD - E-Plan</li>
              </ul>

              <a href="#" class="dflx div_more">
                <h5 class="more">MORE</h5>
                <img class="arrow_wwd" src="../assets/servicios/ar1.png" alt="arrow rigth" />
              </a>

              <section v-for="(d, i) in datapro" :key="i">
                <div class="bloquerelpro" v-if="d.id_category == 5">
                  <a :href="'project/' + d.url">
                    <img src="../assets/icorp.svg" alt="" />
                    <h6>RELATED PROJECTS</h6>
                  </a>
                </div>
              </section>
            </div>
          </div>
        </div>

        <div class="bloq_wwd" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="600">
          <img class="bloq_hdr" src="../assets/servicios/mv2.png" alt="header bloq image" />
          <div class="dflx cnt_bloq_wwd">
            <div class="ico_wwd">
              <img class="ico2" src="../assets/servicios/ico2.png" alt="icon" />
            </div>
            <div class="data_wwd">
              <h2 class="subtitle_wwd">Controls Software Design</h2>
              <p>
                DavisInd Group controls engineers develops complete quality and
                safe systems such as Software design, integration, start up,
                debug and support.
              </p>
              <p>
                Our objective is to design, programming and control systems of
                processes for the industrial sector, to increase the operability
                of a given system.
              </p>
              <ul>
                <li>PLC Safety standard PLC programming,</li>
                <li>Cycle time improvement,</li>
                <li>Human Machin Interface, cameras set up, network setup.</li>
              </ul>
              <p>
                Our expertise includes a wide variety of communication
                protocols such as: profinet, D-net and ethernet.
              </p>
              <p>Brands:</p>
              <ul>
                <li>Rockwell</li>
                <li>Dynics</li>
                <li>Siemens</li>
                <li>Pilz</li>
                <li>Omron</li>
              </ul>

              <a href="#" class="dflx div_more">
                <h5 class="more">MORE</h5>
                <img class="arrow_wwd" src="../assets/servicios/ar1.png" alt="arrow rigth" />
              </a>

              <section v-for="(d, i) in datapro" :key="i">
                <div class="bloquerelpro" v-if="d.id_category == 4">
                  <a :href="'project/' + d.url">
                    <img src="../assets/icorp.svg" alt="" />
                    <h6>RELATED PROJECTS</h6>
                  </a>
                </div>
              </section>
            </div>
          </div>
        </div>
        <div class="bloq_wwd cuatro" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="600">
          <img class="bloq_hdr" src="../assets/servicios/mv3.png" alt="header bloq image" />
          <div class="dflx cnt_bloq_wwd">
            <div class="ico_wwd">
              <img class="ico2" src="../assets/servicios/ico3.png" alt="icon" />
            </div>
            <div class="data_wwd">
              <h2 class="subtitle_wwd">Robotics</h2>
              <p>
                DavisInd Group's robotics department has a diverse group or
                different kind of robotics specialized technicians:
              </p>
              <p class="list-c">
                <span>•</span> Mechatronics engineers. <br />
                <span>•</span> Mechanicals engineers. <br />
                <span>•</span> Electrical engineers. <br />
                <span>•</span> Industrial engineers. <br />
              </p>
              <p>
                Our variety of technicians with different backgrounds allow us
                to allocate the correct person suited for each assignment.
              </p>
              <p>
                Our robotic engineers are well experienced with setup,
                programming and commissioning for many different applications
                and customer standards.
              </p>
              <p>
                We offer project support at any stage of any project, from build
                stage to the final installation and support. This also includes
                supporting every aspect in between, such as line optimization,
                OLP, debug, cycle time improvement. Robotic applications
                supported include spot welding, sealing and dispensing, stud
                welding, MIG welding, riveting, clinch guns, material handling
                pedestal applications and robot guided vision.
              </p>
              <p>
                We have an experience team and we do programming in all brands
                of industrial robots, such as
                <strong>Kawasaki, Fanuc, ABB, Nachi, KUKA, Yaskawa Motoman.
                </strong>
              </p>

              <a href="#" class="dflx div_more">
                <h5 class="more">MORE</h5>
                <img class="arrow_wwd" src="../assets/servicios/ar1.png" alt="arrow rigth" />
              </a>

              <section v-for="(d, i) in datapro" :key="i">
                <div class="bloquerelpro" v-if="d.id_category == 2">
                  <a :href="'project/' + d.url">
                    <img src="../assets/icorp.svg" alt="" />
                    <h6>RELATED PROJECTS</h6>
                  </a>
                </div>
              </section>
            </div>
          </div>
        </div>

        <div class="bloq_wwd" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="600">
          <img class="bloq_hdr" src="../assets/servicios/mv4.png" alt="header bloq image" />
          <div class="dflx cnt_bloq_wwd">
            <div class="ico_wwd">
              <img class="ico1" src="../assets/servicios/ico4.png" alt="icon" />
            </div>
            <div class="data_wwd">
              <h2 class="subtitle_wwd">Tooling and Assembly</h2>
              <p>
                Our engineers provide solutions and design according to our
                client’s specifications. 
              </p>
              <p>
                Computer systems design help us to achieve the maximum precision
                and reduce cost on raw material.
              </p>
              <p>
                Manufacturing of clamping systems, EOAT,locatin pins and work
                fixtures.
              </p>
              <p>Our expertise:</p>
              <ul>
                <li>Concept Development</li>
                <li>Design</li>
                <li>Structural Welding</li>
                <li>Machining</li>
                <li>Precision Machining</li>
                <li>Heat treatment and finishing</li>
                <li>Assembly</li>
              </ul>

              <a href="#" class="dflx div_more">
                <h5 class="more">MORE</h5>
                <img class="arrow_wwd" src="../assets/servicios/ar1.png" alt="arrow rigth" />
              </a>

              <section v-for="(d, i) in datapro" :key="i">
                <div class="bloquerelpro" v-if="d.id_category == 3">
                  <a :href="'project/' + d.url">
                    <img src="../assets/icorp.svg" alt="" />
                    <h6>RELATED PROJECTS</h6>
                  </a>
                </div>
              </section>
            </div>
          </div>
        </div>

        <div class="bloq_wwd" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="600">
          <img class="bloq_hdr" src="../assets/servicios/mv5.png" alt="header bloq image" />
          <div class="dflx cnt_bloq_wwd">
            <div class="ico_wwd">
              <img class="ico3" src="../assets/servicios/ico5.png" alt="icon" />
            </div>
            <div class="data_wwd">
              <h2 class="subtitle_wwd">Install & Commissioning</h2>
              <p>
                Our objective is to ensure the correct installation of automated
                systems as well as ensure we have met the design requirements
                provided by our customers. One of our priorities is verifying
                the equipment before and after the installation face to
                guarantee that no equipment is damaged during installation or at
                power on stage of the project.
              </p>
              <p>Our expertise:</p>
              <ul>
                <li>
                  Robot Installation, trunnions, turntables, work fixtures,
                  fences, and metal structures
                </li>
                <li>Robotics cell relocations</li>
                <li>Electrical and communications wiring</li>
                <li>Control panels built and installation</li>
                <li>Power distribution panels and install</li>
              </ul>

              <a href="#" class="dflx div_more">
                <h5 class="more">MORE</h5>
                <img class="arrow_wwd" src="../assets/servicios/ar1.png" alt="arrow rigth" />
              </a>
              <section v-for="(d, i) in datapro" :key="i">
                <div class="bloquerelpro" v-if="d.id_category == 1">
                  <a :href="'project/' + d.url">
                    <img src="../assets/icorp.svg" alt="" />
                    <h6>RELATED PROJECTS</h6>
                  </a>
                </div>
              </section>
            </div>
          </div>
        </div>
        <div class="bloq_wwd cuatro" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="600">
          <img class="bloq_hdr" src="../assets/servicios/mv6.png" alt="header bloq image" />
          <div class="dflx cnt_bloq_wwd">
            <div class="ico_wwd">
              <img class="ico1" src="../assets/servicios/ico6.png" alt="icon" />
            </div>
            <div class="data_wwd">
              <h2 class="subtitle_wwd">On-Site Commissioning <br>
                & Support</h2>
              <p>
                Our commissioning team ensures correct installation and adherence to design requirements. We prioritize
                pre- and post-installation equipment checks to prevent damage. Our expertise includes:
              </p>
              <p>
                Robot Installation, trunnions, turntables, work fixtures, fences, and metal structures. 
                Robotics cell relocations.
                Vision Systems.
                Peripheral devices are set up and ready to start running.
                Electrical and communications wiring. <br>
                Control panels built and installation. <br>
                Power distribution panels and install. <br>
              </p>
              <p>
                Our diverse team comprises mechatronics, mechanical, and electrical engineers, enabling tailored
                assignments. With extensive experience in setup, programming, and commissioning, we support projects at
                all stages, including line optimization and cycle time improvement. We handle various robotic
                applications and programming across all major industrial robot brands.
              </p>
              <p>
                For controls, we specialize in communication protocols like Profinet, D-net, and Ethernet, managing
                controllers from brands such as Rockwell, Dynic’s, Siemens, Pilz, and Omron.
              </p>

              <a href="#" class="dflx div_more">
                <h5 class="more">MORE</h5>
                <img class="arrow_wwd" src="../assets/servicios/ar1.png" alt="arrow rigth" />
              </a>


              <div class="bloquerelpro">
                <a href="/installation-and-commissioning">
                  <img src="../assets/icorp.svg" alt="" />
                  <h6>RELATED PROJECTS</h6>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="pc_wwd">
        <div class="bloq_wwd" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="600">
          <img class="bloq_hdr mr4" src="../assets/servicios/pc1.png" alt="header bloq image" />
          <div class="dflx cnt_bloq_wwd">
            <div class="ico_wwd">
              <img class="ico1" src="../assets/servicios/ico1.png" alt="icon" />
            </div>
            <div class="data_wwd">
              <h2 class="subtitle_wwd">Hardware Controls System Design</h2>
              <p>
                We provide complete design of the automated systems for
                Automotive, Mining and food and beverage. Design of networks,
                electrical, pneumatic, and hydraulic using technological
                innovation in the automation industry.
              </p>
              <p>
                Our team provides the design department, information that is
                needed for development and integration of a functional system.
              </p>

              <ul>
                <li>Layouts</li>
                <li>
                  Communication protocols: Ethernet, Devicenet, Profinet, etc.
                </li>
                <li>Software Design – AutoCAD - E-Plan</li>
              </ul>

              <a href="#" class="dflx div_more">
                <h5 class="more">MORE</h5>
                <img class="arrow_wwd" src="../assets/servicios/ar1.png" alt="arrow rigth" />
              </a>

              <section v-for="(d, i) in datapro" :key="i">
                <div class="bloquerelpro" v-if="d.id_category == 5">
                  <a :href="'project/' + d.url">
                    <img src="../assets/icorp.svg" alt="" />
                    <h6>RELATED PROJECTS</h6>
                  </a>
                </div>
              </section>
            </div>
          </div>
        </div>
        <div class="bloq_wwd" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="600">
          <div class="dflx cnt_bloq_wwd mr4">
            <div class="ico_wwd">
              <img class="ico1" src="../assets/servicios/ico2.png" alt="icon" />
            </div>
            <div class="data_wwd">
              <h2 class="subtitle_wwd">Controls Software Design</h2>
              <p>
                DavisInd Group controls engineers develops complete quality and
                safe systems such as Software design, integration, start up,
                debug and support.
              </p>
              <p>
                Our objective is to design, programming and control systems of
                processes for the industrial sector, to increase the operability
                of a given system.
              </p>
              <ul>
                <li>PLC Safety standard PLC programming,</li>
                <li>Cycle time improvement,</li>
                <li>Human Machin Interface, cameras set up, network setup.</li>
              </ul>
              <p>
                Our expertise includes a wide variety of communication
                protocols such as: profinet, D-net and ethernet.
              </p>
              <p>Brands:</p>
              <ul>
                <li>Rockwell</li>
                <li>Dynics</li>
                <li>Siemens</li>
                <li>Pilz</li>
                <li>Omron</li>
              </ul>

              <a href="#" class="dflx div_more">
                <h5 class="more">MORE</h5>
                <img class="arrow_wwd" src="../assets/servicios/ar1.png" alt="arrow rigth" />
              </a>

              <section v-for="(d, i) in datapro" :key="i">
                <div class="bloquerelpro" v-if="d.id_category == 4">
                  <a :href="'project/' + d.url">
                    <img src="../assets/icorp.svg" alt="" />
                    <h6>RELATED PROJECTS</h6>
                  </a>
                </div>
              </section>
            </div>
          </div>
          <img class="bloq_hdr" src="../assets/servicios/pc2.png" alt="header bloq image" />
        </div>

        <div class="bloq_wwd" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="600">
          <img class="bloq_hdr mr4" src="../assets/servicios/pc3.png" alt="header bloq image" />
          <div class="dflx cnt_bloq_wwd">
            <div class="ico_wwd">
              <img class="ico2" src="../assets/servicios/ico4.png" alt="icon" />
            </div>
            <div class="data_wwd">
              <h2 class="subtitle_wwd">Tooling and Assembly</h2>
              <p>
                Our engineers provide solutions and design according to our
                client’s specifications. 
              </p>
              <p>
                Computer systems design help us to achieve the maximum precision
                and reduce cost on raw material.
              </p>
              <p>
                Manufacturing of clamping systems, EOAT,locatin pins and work
                fixtures.
              </p>
              <p>Our expertise:</p>
              <ul>
                <li>Concept Development</li>
                <li>Design</li>
                <li>Structural Welding</li>
                <li>Machining</li>
                <li>Precision Machining</li>
                <li>Heat treatment and finishing</li>
                <li>Assembly</li>
              </ul>

              <a href="#" class="dflx div_more">
                <h5 class="more">MORE</h5>
                <img class="arrow_wwd" src="../assets/servicios/ar1.png" alt="arrow rigth" />
              </a>

              <section v-for="(d, i) in datapro" :key="i">
                <div class="bloquerelpro" v-if="d.id_category == 3">
                  <a :href="'project/' + d.url">
                    <img src="../assets/icorp.svg" alt="" />
                    <h6>RELATED PROJECTS</h6>
                  </a>
                </div>
              </section>
            </div>
          </div>
        </div>

        <div class="bloq_wwd cuatro" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="600">
          <div class="dflx cnt_bloq_wwd mr4">
            <div class="ico_wwd">
              <img class="ico1" src="../assets/servicios/ico3.png" alt="icon" />
            </div>
            <div class="data_wwd">
              <h2 class="subtitle_wwd">Robotics</h2>
              <p>
                DavisInd Group's robotics department has a diverse group or
                different kind of robotics specialized technicians:
              </p>
              <p class="list-c">
                <span>•</span> Mechatronics engineers. <br />
                <span>•</span> Mechanicals engineers. <br />
                <span>•</span> Electrical engineers. <br />
                <span>•</span> Industrial engineers. <br />
              </p>
              <p>
                Our variety of technicians with different backgrounds allow us
                to allocate the correct person suited for each assignment.
              </p>
              <p>
                Our robotic engineers are well experienced with setup,
                programming and commissioning for many different applications
                and customer standards.
              </p>
              <p>
                We offer project support at any stage of any project, from build
                stage to the final installation and support. This also includes
                supporting every aspect in between, such as line optimization,
                OLP, debug, cycle time improvement. Robotic applications
                supported include spot welding, sealing and dispensing, stud
                welding, MIG welding, riveting, clinch guns, material handling
                pedestal applications and robot guided vision.
              </p>
              <p>
                We have an experience team and we do programming in all brands
                of industrial robots, such as
                <strong>Kawasaki, Fanuc, ABB, Nachi, KUKA, Yaskawa Motoman.
                </strong>
              </p>

              <a href="#" class="dflx div_more">
                <h5 class="more">MORE</h5>
                <img class="arrow_wwd" src="../assets/servicios/ar1.png" alt="arrow rigth" />
              </a>

              <section v-for="(d, i) in datapro" :key="i">
                <div class="bloquerelpro" v-if="d.id_category == 2">
                  <a :href="'project/' + d.url">
                    <img src="../assets/icorp.svg" alt="" />
                    <h6>RELATED PROJECTS</h6>
                  </a>
                </div>
              </section>
            </div>
          </div>
          <img class="bloq_hdr" src="../assets/servicios/pc4.png" alt="header bloq image" />
        </div>

        <div class="bloq_wwd" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="600">
          <img class="bloq_hdr mr4" src="../assets/servicios/pc5.png" alt="header bloq image" />
          <div class="dflx cnt_bloq_wwd">
            <div class="ico_wwd">
              <img class="ico1" src="../assets/servicios/ico5.png" alt="icon" />
            </div>
            <div class="data_wwd">
              <h2 class="subtitle_wwd">Install & Commissioning</h2>
              <p>
                Our objective is to ensure the correct installation of automated
                systems as well as ensure we have met the design requirements
                provided by our customers. One of our priorities is verifying
                the equipment before and after the installation face to
                guarantee that no equipment is damaged during installation or at
                power on stage of the project.
              </p>
              <p>Our expertise:</p>
              <ul>
                <li>
                  Robot Installation, trunnions, turntables, work fixtures,
                  fences, and metal structures
                </li>
                <li>Robotics cell relocations</li>
                <li>Electrical and communications wiring</li>
                <li>Control panels built and installation</li>
                <li>Power distribution panels and install</li>
              </ul>

              <a href="#" class="dflx div_more">
                <h5 class="more">MORE</h5>
                <img class="arrow_wwd" src="../assets/servicios/ar1.png" alt="arrow rigth" />
              </a>

              <section v-for="(d, i) in datapro" :key="i">
                <div class="bloquerelpro" v-if="d.id_category == 1">
                  <a :href="'project/' + d.url">
                    <img src="../assets/icorp.svg" alt="" />
                    <h6>RELATED PROJECTS</h6>
                  </a>
                </div>
              </section>
            </div>
          </div>
        </div>

        <div class="bloq_wwd cuatro" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="600">
          <div class="dflx cnt_bloq_wwd mr4">
            <div class="ico_wwd">
              <img class="ico6" src="../assets/servicios/ico6.png" alt="icon" />
            </div>
            <div class="data_wwd">
              <h2 class="subtitle_wwd">On-Site Commissioning <br>
                & Support</h2>
              <p>
                Our commissioning team ensures correct installation and adherence to design requirements. We prioritize
                pre- and post-installation equipment checks to prevent damage. Our expertise includes:
              </p>
              <p class="list-c">
                <span>•</span> Robot Installation, trunnions, turntables, work fixtures, ㅤㅤﾠ fences, and metal
                structures.<br />
                <span>•</span> Robotics cell relocations. <br />
                <span>•</span> Vision Systems. <br />
                <span>•</span> Peripheral devices are set up and ready to start running. <br />
                <span>•</span> Electrical and communications wiring. <br />
                <span>•</span> Control panels built and installation. <br />
                <span>•</span> Power distribution panels and install. <br />

              </p>
              <p>
                Our diverse team comprises mechatronics, mechanical, and electrical engineers, enabling tailored
                assignments. With extensive experience in setup, programming, and commissioning, we support projects at
                all stages, including line optimization and cycle time improvement. We handle various robotic
                applications and programming across all major industrial robot brands.
              </p>
              <p>
                For controls, we specialize in communication protocols like Profinet, D-net, and Ethernet, managing
                controllers from brands such as Rockwell, Dynic’s, Siemens, Pilz, and Omron.
              </p>

              <a href="#" class="dflx div_more">
                <h5 class="more">MORE</h5>
                <img class="arrow_wwd" src="../assets/servicios/ar1.png" alt="arrow rigth" />
              </a>


              <div class="bloquerelpro">
                <a href="/installation-and-commissioning">
                  <img src="../assets/icorp.svg" alt="" />
                  <h6>RELATED PROJECTS</h6>
                </a>
              </div>

            </div>
          </div>
          <img class="bloq_hdr" src="../assets/servicios/pc6.png" alt="header bloq image" />
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Navigator from "../components/Navigator";
import { mapActions } from "vuex";
import Footer from "../components/Footer.vue";
export default {
  name: "WhatWeDoView",
  components: {
    Navigator,
    Footer,
  },
  async created() {
    await this.getAllInfoPrj("project");
  },
  computed: {
    datapro() {
      let data = this.$store.getters["project/data"];
      let arr = [];
      for (let i = 0; i < data.length; i++) {
        if (data[i].statusproject == "VISIBLE") {
          arr.push(data[i]);
        }
      }
      return arr;
    },
  },
  methods: {
    ...mapActions("project", ["getAllInfoPrj"]),
    ...mapActions("project", ["setHistoryOptionPrj"]),
    ...mapActions("project", ["setAddedPrj"]),
  },
};
</script>
<style scoped>
#pc_wwd {
  display: none;
}

#section_wwd {
  background-image: url("../assets/servicios/log.png");
  background-repeat: no-repeat;
  background-size: 57.813084vw;
  background-position: 42vw 29.5vw;
}

#cnt_wwd {
  margin: 0vw 3.2710280373831773vw;
}

h1.title_wwd {
  text-align: center;
  margin: 0px auto;
  color: var(--color-2);
  font-family: var(--MOSB_Regular);
  font-style: normal;
  font-weight: 400;
  font-size: 8.177vw;
  line-height: 10.28vw;
  padding-top: 60.981vw;
  margin-bottom: 27.205607vw;
}

h1.title_wwd span {
  color: var(--color-1);
}

h2.subtitle_wwd {
  font-family: var(--MOSB_Regular);
  font-style: normal;
  font-weight: 400;
  font-size: 3.972vw;
  line-height: 5.14vw;
  width: 37.850467289719624vw;
  background: linear-gradient(180deg, #262ffd 0%, #06c5ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin: 2vw 0vw;
  margin-bottom: 13.084112149532709vw;
}

a.div_more {
  text-decoration: none;
  display: none;
}

img.bloq_hdr {
  height: 52.80373831775701vw;
  width: 100%;
}

.cnt_bloq_wwd {
  margin-top: 10.2803738317757vw;
}

/* 3.280374vw */

.bloq_wwd {
  margin-bottom: 23.364485981308412vw;
}

.ico_wwd img.ico1 {
  height: 14.719626168224298vw;
  width: 14.719626168224298vw;
  margin-left: 5.841121495327103vw;
  margin-right: 8vw;
}

.ico_wwd img.ico2 {
  height: 14.719626168224298vw;
  width: 14.719626168224298vw;
  margin-left: 7.841121vw;
  margin-right: 7vw;
}

.ico_wwd img.ico3 {
  height: 14.719626168224298vw;
  width: 14.719626168224298vw;
  margin-left: 10.841121vw;
  margin-right: 6vw;
}

.data_wwd {
  width: 56.074766355140184vw;
}

h5.more {
  font-family: var(--MOSB_Regular);
  font-style: normal;
  font-weight: 400;
  font-size: 2.570093457943925vw;
  line-height: 3.2710280373831773vw;
  text-align: center;
  text-transform: uppercase;
  width: 11.682242990654206vw;
  color: #262ffd;
  margin-right: 3.9719626168224296vw;
}

img.arrow_wwd {
  width: 13.317757009345794vw;
  height: 2vw;
  padding-top: 5vw;
}

.div_more {
  margin-top: 6.5420560747663545vw;
  cursor: pointer;
}

.data_wwd p,
li {
  font-family: var(--OpenSans);
  font-style: normal;
  font-weight: 400;
  font-size: 3.2710280373831773vw;
  line-height: 152.68%;
  color: var(--color-3);
}

.data_wwd ul {
  padding-left: 6vw;
}

.italic {
  font-style: italic !important;
}

p.list-c span {
  padding: 0 0.52vw 0 3vw;
}

.bloquerelpro {
  margin-top: 15vw;
}

.bloquerelpro a {
  display: flex;
  text-decoration: none;
}

.bloquerelpro a h6 {
  color: #262ffd;
  font-family: var(--MOSB_Regular);
  font-size: 2.570093457943925vw;
  margin: 0;
  margin-left: 2.5vw;
}

.bloquerelpro a img {
  margin-top: 0.5vw;
  width: 4.672897196261682vw;
  height: 2.570093457943925vw;
}

@media (max-width: 1024px) {
  #section_proind .agile__actions {
    display: none;
  }

  #section_proind .agile {
    padding-bottom: 6vw;
  }
}

@media (min-width: 1024px) {
  #movil_wwd {
    display: none;
  }

  #pc_wwd {
    display: block;
    padding-bottom: 7vw;
  }

  h1.title_wwd {
    font-size: 3.6458333333333335vw;
    line-height: 4.635416666666667vw;
    padding-top: 16.615vw;
    text-align: left;
    margin-left: 7.708333333333334vw;
    margin-bottom: 9.53125vw;
  }

  .cnt_bloq_wwd {
    margin-top: 10.2803738317757vw;
    width: 48%;
  }

  .bloq_wwd.cuatro .cnt_bloq_wwd {
    margin-top: 3.073vw;
  }

  #section_wwd {
    background-position: 54vw -10.5vw;
    background-size: 45.813084vw;
  }

  .bloq_wwd {
    display: flex;
    margin-bottom: 4.479166666666667vw;
  }

  .mr4 {
    margin-right: 4%;
  }

  #cnt_wwd {
    margin: 0vw;
  }

  img.bloq_hdr {
    height: 54.79166666666667vw;
    width: 48%;
  }

  .ico_wwd img.ico1 {
    width: 5.885416666666667vw;
    height: 5.885416666666667vw;
    margin-left: 6.354166666666666vw;
    margin-right: 3vw;
  }

  .ico_wwd img.ico6 {
    width: 8.385416666666667vw;
    height: 6.885416666666667vw;
    margin-left: 5.354166666666666vw;
    margin-right: 3vw;
  }

  .ico_wwd img.ico2 {
    width: 5.885416666666667vw;
    height: 5.885416666666667vw;
    margin-left: 6.354166666666666vw;
    margin-right: 3vw;
  }

  .ico_wwd {
    width: 16.373832vw;
  }

  h2.subtitle_wwd {
    font-size: 1.5625vw;
    line-height: 1.9791666666666665vw;
    width: 23.28125vw;
    margin-bottom: 4.010416666666667vw;
  }

  .data_wwd p,
  li {
    width: 26.833333vw;
    font-size: 0.9375vw;
    line-height: 152.68%;
  }

  .data_wwd ul {
    padding-left: 2vw;
  }

  h5.more {
    font-size: 0.6770833333333334vw;
    line-height: 0.8333333333333334vw;

    width: 2.604166666666667vw;

    margin-right: 0.8854166666666666vw;
  }

  img.arrow_wwd {
    width: 2.96875vw;
    height: 0.5vw;
    padding-top: 1.3vw;
  }

  .div_more {
    margin-top: 2.604166666666667vw;
  }

  p.list-c span {
    padding: 0 0.32vw 0 0.7vw;
  }

  .bloquerelpro {
    margin-top: 4vw;
  }

  .bloquerelpro a h6 {
    font-size: 0.6770833333333334vw;
    margin: 0;
    margin-left: 1vw;
  }

  .bloquerelpro a img {
    margin-top: 0.1vw;
    width: 1.4583333333333333vw;
    height: 0.8333333333333334vw;
  }
}
</style>
